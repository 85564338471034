import React, { useEffect, useState } from 'react'
import trustIcon from '../img/phantom.png'
import { FileCopy } from '@material-ui/icons';


function PhantomWallate() {
    const [isWalletAvailable, setIsWalletAvailable] = useState(false);
    const [account, setAccount] = useState(null);

    const handleConnectWallet = async () => {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        console.log(accounts[0])
        setAccount(accounts[0]);
    }
    useEffect(() => {
        if (window.ethereum) {
            setIsWalletAvailable(true)
        } else {
            alert('Trust Wallet is not installed. Please install Trust Wallet to use this feature.');
        }
    }, [])
    return (
        <a className="btn btn-grad btn-sm" onClick={handleConnectWallet}>
            {account ? (<>
                <img src={trustIcon} alt='' className='hight-24' />{' '}
                <span className='address-show'>{account.substring(0, 5)}... {account.slice(-5)} </span>
                <FileCopy className='small-icon' />
            </>) : (<><img src={trustIcon} alt='' className='hight-24' /> wallet</>)}

        </a>
    )
}


export default PhantomWallate